<template>
  <master-detail
    formTitle="Cadastro tipo Convênio"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    descriptionProperty="nome"
    :hasYearFilter="hasYearFilter"
    @onOpenFormDialog="getProjetosExternos"
  ></master-detail>
</template>

<script>
import moment from 'moment';
import yearsOptions from '@/helpers/yearsOptions';
import { mapGetters } from 'vuex';

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    hasYearFilter: function () {
      return { max: moment().add(1, 'year').get('year') };
    },
    resourceUrl: function () {
      return `/v1/faturamento/projext/tipoConvenio/${this.clientId}`;
    },
    resourceProjeto: function () {
      return this.apiResource(`/v1/faturamento/projext/projeto/${this.clientId}`);
    },
  },
  data: function () {
    return {     
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "data_pagamento",
          name: "Data Pagamento",
          type: this.$fieldTypes.DATE,
          colSize: 6,
        },
        {
          key: "valor",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
        },       
        {
          key: "projetoextId",
          name: "Projeto Externo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "projetos", key: "id", name: "nome_projeto"},
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "trimestre_obrigacao",
          name: "Trimestre Obrigação",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'trimestres', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "anoBase_obrigacao",
          name: "Ano Obrigação",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'anos', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "trimestre_credito",
          name: "Trimestre Crédito",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'trimestres', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "anoBase_credito",
          name: "Ano Crédito",
          type: this.$fieldTypes.SELECT,
          rel: { to: 'anos', key: 'value', name: 'text' },
          colSize: 6,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        enquadramento: [],
        projetos: [],
        trimestres: [
          {
            text: 'Primeiro',
            value: 1,
          },
          {
            text: 'Segundo',
            value: 2,
          },
          {
            text: 'Terceiro',
            value: 3,
          },
          {
            text: 'Quarto',
            value: 4,
          },
        ],
        anos: yearsOptions,
      },
    };
  },
  methods: {
    getProjetosExternos: function () {
      if (!this.opts.projetos.length) {
        this.resourceProjeto.get().then((response) => {
          this.opts.projetos = response.sort(({ nome_projeto: a }, { nome_projeto: b }) => a?.localeCompare(b));
        });
      }
    }
  }
};
</script>
